<template>
  <div
    class="cursor-pointer relative"
    @click="onClick"
  >
    <nuxt-link
      class="flex items-center w-full gap-x-4 py-8 pl-20 pr-[1.3rem] overflow-visible [&.lnb-item-active>span]:text-brand-primary"
      activeClass="lnb-item-active"
      :to="external ? undefined : to"
      @click="onClick"
    >
      <s-text
        as="i"
        role="cap1"
        class="shrink-0 !font-bold text-brand-primary bg-[#E2EAFF] py-[0.3rem] px-[0.6rem] rounded-lg"
      >
        {{ tag }}
      </s-text>
      <st-tooltip-text-over position="bottom-start" customCss="!block font-medium text-on-surface-elevation-2 text-md leading-sm overflow-hidden whitespace-nowrap overflow-ellipsis" :content="fullName" tippyClass="group-lnb-tippy">
        <slot></slot>
      </st-tooltip-text-over>
    </nuxt-link>
  </div>
</template>
<script setup lang="ts">
import { useRouter } from 'vue-router';

import StTooltipTextOver from '@/components/common/st-tooltip-text-over.vue';
import { redirectTo } from '@/utils/common.util';

interface LNBChildItem {
  to?: string;
  external?: boolean;
  tag?: string;
  fullName: string;
  isSelected?: boolean;
}

const props = defineProps<LNBChildItem>();

const router = useRouter();

const onClick = async (e: Event) => {
  e.stopPropagation();
  if (!props.to) {
    return;
  }
  if (!props.external) {
    return await router.push(props.to);
  }
  return await redirectTo(props.to, { external: true });
};
</script>
