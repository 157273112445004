<template>
  <s-contents-popup
    v-if="hasGroupList"
    placement="bottom-start"
    trigger="click"
    :distance="4"
    :offset="[0, 0]"
    :minWidth="224"
    :flipOnUpdate="false"
    :theme="'studio-lnb-group'"
  >
    <template #target>
      <div ref="selectedGroupRef" class="flex items-center gap-x-[1rem] py-8">
        <div class="flex items-center">
          <span
            class="text-on-surface-elevation-1 line-clamp-1 font-bold text-2xl leading-xl max-w-[17rem] pr-[0.1rem]"
          >
            {{ selectedGroupName }}
          </span>
          <s-icon
            size="xl"
            icon="ic-v2-control-arrow-down-line"
            class="ml-4 shrink-0 text-on-surface-elevation-1"
          />
        </div>
        <em
          v-if="teamOptions.length > 1"
          class="shrink-0 ml-auto bg-on-surface-elevation-1 text-surface-elevation-1 font-bold text-md leading-lg px-[.5rem] text-center min-w-24 rounded-xl"
        >
          {{ teamOptions.length }}
        </em>
      </div>
    </template>
    <template #contents>
      <div class="py-8">
        <team-item
          v-for="team in teamOptions"
          :key="team.value"
          :value="team.value"
          :isSelected="team.value === selectedGroupId"
          :hasOwnership="team.hasOwnership"
          @onSelected="handleGroupChange"
        >
          {{ team.label }}
        </team-item>

        <!-- <team-item hasOwnership>%그룹명-owner%</team-item>
        <team-item isNew>%그룹명-new%</team-item>
        <team-item isSelected>%그룹명-selected%</team-item> -->
        <!-- 새 그룹 만들기 버튼 -->
        <button
          type="button"
          class="flex w-full min-h-40 px-16 py-8 items-center hover:bg-blue50"
          @click="openCreateGroupDialog"
        >
          <span
            class="break-all text-left line-clamp-1 text-md leading-sm font-medium text-on-surface-elevation-3"
          >
            {{ $t('studio.group.home.btn_create_new_group') }}
          </span>
          <s-icon
            size="xl"
            icon="ic-v2-control-add-line"
            class="shrink-0 ml-4 text-on-surface-elevation-3"
          />
        </button>
      </div>
    </template>
  </s-contents-popup>
  <div v-else class="flex items-center gap-x-[1rem] py-8">
    <span class="text-on-surface-elevation-1 line-clamp-1 font-bold text-2xl leading-xl">
      %그룹명은한줄후줄임말%
    </span>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { validationGroupsApi } from '@/apis/group.api';
import TeamItem from '@/components/app/lnb/team-item.vue';
import CreateGroupDialog from '@/components/group/group-form-dialog.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { STATUS_CODE } from '@/constants/error.const';
import { HOME_PAGE_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { useUserStore } from '@/stores/user.store';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { SimpleGroupResponse } from '@/types/my-info/my-info-response.type';
import { redirectTo } from '@/utils/common.util';

defineProps<{
  hasGroupList: boolean;
}>();

const { t, locale } = useI18n();

const selectedGroupRef = ref<HTMLElement>();

const userStore = useUserStore();
const { joinedGroups, selectedGroupId } = storeToRefs(userStore);

const teamOptions = computed(
  () =>
    joinedGroups.value?.map((team: SimpleGroupResponse) => ({
      label: team.groupName,
      value: team.groupId,
      hasOwnership: team.ownerYn === Confirmation.YES
    })) ?? []
);

const selectedGroupName = computed(() => {
  const selectedTeam = teamOptions.value.find(
    (team: FormOption) => team.value === selectedGroupId.value
  );
  return selectedTeam?.label ?? '';
});

const openCreateGroupDialog = async () => {
  try {
    await validationGroupsApi();
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;

    if (errorCode === STATUS_CODE.GROUP_MAX_OWNED) {
      await showAlert({
        content: t('studio.stu_logged_in.pre_grp_join.create_over_10grp_msg'),
        confirmLabel: t('studio.common.popup_case_cf_btn')
      });
      return;
    }

    return;
  }
  const result = await showDialog({
    component: shallowRef(CreateGroupDialog),
    props: {
      header: 'Create Popup Dialog'
    },
    severity: 'info'
  });
  if (result === 'createSuccess') {
    await redirectTo(HOME_PAGE_URL, { groupId: selectedGroupId.value });
  }
};

const handleGroupChange = async (selectedId: string) => {
  selectedGroupRef.value?.click();
  if (selectedGroupId.value === selectedId) {
    return;
  }
  const selectedGroup = joinedGroups.value?.find(
    (group: SimpleGroupResponse) => group.groupId === selectedId
  );
  if (selectedGroup?.onMigrationYn === Confirmation.YES || selectedGroup?.onProjectMigrationYn === Confirmation.YES) {
    await showAlert({
      content: t('studio.transfer_in_progress.all_features_unavailable_msg'),
      panelClasses: 'w-[39.2rem]'
    });
    return;
  }
  redirectTo(`/${locale.value}${HOME_PAGE_URL}`, {
    external: true,
    groupId: selectedId,
    withLocale: false
  });
};
</script>
