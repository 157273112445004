<template>
  <nuxt-link
    v-slot="slotProps"
    :prefetch="false"
    :to="external ? undefined : to"
    class="lnb-item flex w-full gap-x-8 rounded-3xl pl-16 pr-12 py-[1rem] hover:bg-blue50 cursor-pointer"
    @click="onClick"
  >
    <s-icon v-if="icon" size="3xl" :icon="icon" class="shrink-0" />
    <component
      :is="iconSvg"
      v-else-if="iconSvg"
      :color="slotProps?.isActive ? '#fff' : '#1F1F1F'"
    />
    <span class="flex-1 flex items-start gap-4 text-lg leading-sm">
      <slot></slot>
      <template v-if="latestNotice && hasNewNotify">
        <s-new-badge
          v-if="isNewNotice(latestNotice.createDatetime)"
          variant="dot"
          class="bg-tint-red-a400"
        />
      </template>
    </span>
  </nuxt-link>
  <div v-if="Array.isArray(children) && children.length" class="ml-[2.6rem] relative short-border">
    <lnb-child-item
      v-for="{ to: toUrl, tag, isSelected, name, productNo, external: lnbExternal } in children"
      :key="productNo"
      :tag="tag"
      :isSelected="isSelected"
      :to="toUrl"
      :fullName="name"
      :external="lnbExternal"
    >
      {{ name }}
    </lnb-child-item>
    <s-text
      v-if="maxChildrenNumber && children.length >= maxChildrenNumber"
      as="a"
      role="title8"
      href="#"
      class="block text-on-surface-elevation-3 py-[1rem] pr-8 pl-20"
      @click.prevent="gotoProjectManagement"
    >
      {{ $t('studio.lnb.prj_prod_mgmt.view_all_btn') }}
    </s-text>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';

import LnbChildItem from '@/components/app/lnb/lnb-child-item.vue';
import { useApp } from '@/composables/useApp';
import { showAlert } from '@/composables/useDialog';
import { PROJECT_MNG_PAGE_URL } from '@/constants/url.const';
import { useNoticeStore } from '@/stores/notice.store';
import { useUserStore } from '@/stores/user.store';
import type { LNBItem } from '@/types/lnb.type';
import { generateExternalLink, redirectTo } from '@/utils/common.util';
import { isNewNotice } from '@/utils/notice.util';

const i18n = useI18n();
const app = useApp();
const userStore = useUserStore();
const { selectedGroupId } = storeToRefs(userStore);

const props = defineProps<LNBItem>();

const gotoProjectManagement = () => {
  redirectTo(PROJECT_MNG_PAGE_URL);
};

const onClick = async () => {
  if (!props.external) {
    return;
  }
  const isReadable = props.menuAuthId
    ? (await app.getMenuPermission(props.menuAuthId)).isReadable
    : false;
  if (!isReadable) {
    return await showAlert({ content: i18n.t('studio.common.popup_case_g_not_view') });
  }
  const url = await generateExternalLink(props.to ?? '', i18n.locale.value, selectedGroupId.value);
  return await redirectTo(url, { external: true });
};

const noticeStore = useNoticeStore();
const { latestNotice } = storeToRefs(noticeStore);
</script>

<style lang="scss" scoped>
.lnb-item:not(.router-link-active) {
  @apply text-on-surface-elevation-1;
  span {
    @apply font-medium;
  }
}
.lnb-item.router-link-active {
  @apply bg-brand-primary text-white shadow-[0_0.2rem_0.8rem_rgba(28,78,255,0.4)];
  span {
    @apply font-bold;
  }
}
</style>
