<template>
  <div class="flex flex-col min-w-[128rem] max-w-[136rem] min-h-[100vh] mx-auto">
    <gnb :isScrolled="isScrolled" />
    <div class="flex flex-1 gap-x-24 pr-24 pb-[12rem] pt-[6.8rem]">
      <lnb />
      <div class="w-[96rem] min-w-0 ml-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

import Gnb from '@/components/app/gnb-header.vue';
import Lnb from '@/components/app/lnb/index.vue';
import { useAppStore } from '@/stores/app.store';

const appStore = useAppStore();
const isScrolled = ref(false);

const init = async () => {
  await appStore.fetchLanguages();
};

init();

const handleScroll = () => {
  isScrolled.value = window.scrollY > 0;
};

const adjustFooter = () => {
  nextTick(() => {
    const footerWrapper = document.querySelector('#footer-wrapper');
    if (footerWrapper) {
      footerWrapper.classList.add('adjust-footer');
    }
  });
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  adjustFooter();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>
<style lang="scss" src="~~/assets/css/main.scss" />
