<template>
  <div>
    <button
      type="button"
      class="flex w-full gap-x-8 rounded-3xl pl-16 pr-12 py-[1rem] hover:bg-blue50"
      :class="'text-on-surface-elevation-1'"
      @click="isOpen = !isOpen"
    >
      <s-icon size="3xl" :icon="'ic-v2-stove-symbol-line'" class="shrink-0" />
      <span class="text-left flex-1 text-lg leading-sm" :class="'font-medium'">
        {{ $t('studio.lnb.common_2depth_creator_news') }}
      </span>
      <s-icon
        size="3xl"
        icon="ic-v2-control-arrow-down-line"
        class="shrink-0 ml-12"
        :class="{ 'rotate-180': isOpen }"
      />
    </button>
    <div v-if="isOpen">
      <!-- <nuxt-link
        :to="creatorCommunityUrl"
        :prefetch="false"
        class="flex w-full gap-x-8 rounded-[1.8rem] py-8 pl-20 pr-16 hover:bg-[#E7EEFF]"
      >
        <s-icon
          size="xl"
          icon="ic-v2-navigation-sub-menu-line"
          class="shrink-0 text-on-surface-elevation-4"
        />
        <span class="flex-1 text-md leading-sm text-on-surface-elevation-1">
          {{ $t('studio.lnb.common_2depth_creator_community') }}
        </span>
      </nuxt-link> -->
      <a
        href="https://forcreators.stoveindie.com/"
        target="_blank"
        class="flex w-full gap-x-8 rounded-3xl pl-16 pr-12 py-[1rem] hover:bg-[#E7EEFF]"
      >
        <s-icon
          size="xl"
          icon="ic-v2-navigation-sub-menu-line"
          class="shrink-0 text-on-surface-elevation-4"
        />
        <span class="flex-1 text-md leading-sm text-on-surface-elevation-1">
          {{ $t('studio.lnb.common_2depth_creator_benefit') }}
        </span>
        <s-icon
          size="xl"
          icon="ic-v2-control-web-link-line"
          class="shrink-0 mt-2 text-on-surface-elevation-4"
        />
      </a>
      <a
        href="https://discord.com/invite/jdRFRQJrHa"
        target="_blank"
        class="flex w-full gap-x-8 rounded-3xl pl-16 pr-12 py-[1rem] hover:bg-[#E7EEFF]"
      >
        <s-icon
          size="xl"
          icon="ic-v2-navigation-sub-menu-line"
          class="shrink-0 text-on-surface-elevation-4"
        />
        <span class="flex-1 text-md leading-sm text-on-surface-elevation-1">
          {{ $t('studio.lnb.common_2depth_discord') }}
        </span>
        <s-icon
          size="xl"
          icon="ic-v2-control-web-link-line"
          class="shrink-0 mt-2 text-on-surface-elevation-4"
        />
      </a>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';

const isOpen = ref(false);

// const creatorCommunityUrl = computed(() => {
//   const { STUDIO_V2_URL } = getConfigs();
//   const host = STUDIO_V2_URL;
//
//   return `${host}/${locale.value}/${selectedGroupId.value}/creators-community/list`;
// });
</script>
