<template>
  <div
    ref="groupLnb"
    class="shrink-0 w-272 px-24 py-16 fixed top-[6.8rem] self-start max-h-[calc(100vh-6.8rem)] overflow-auto no-scrollbar"
  >
    <!-- LNB 그룹리스트 -->
    <team-selector hasGroupList />

    <!-- LNB 메뉴 -->
    <div
      class="mt-12 divide-solid divide-y-1 divide-gray150 border-solid border-t-1 border-gray150"
    >
      <div class="py-20 first:pt-24 last:pb-0">
        <p class="mb-8 text-sm font-medium leading-md text-on-surface-elevation-3">
          {{ $t('studio.lnb.common_1depth_studio_group') }}
        </p>
        <div class="flex flex-col gap-y-2">
          <lnb-item
            v-for="(item, index) in lnbItems"
            :key="index"
            :to="item.to"
            :external="item.external ?? false"
            :icon="item.icon"
            :menuAuthId="item.menuAuthId"
            :children="item.children"
            :maxChildrenNumber="item.maxChildrenNumber"
          >
            {{ $t(item.label || '') }}
          </lnb-item>
        </div>
      </div>
      <div class="py-20 first:pt-24 last:pb-0">
        <p class="mb-8 text-sm font-medium leading-md text-on-surface-elevation-3">
          {{ $t('studio.lnb.common_1depth_studio_support') }}
        </p>
        <div class="flex flex-col gap-y-2">
          <creator-news />
          <nuxt-link
            :to="getDeveloperGuideUrl()"
            target="_blank"
            :prefetch="false"
            class="flex w-full gap-x-8 rounded-3xl pl-16 pr-12 py-[1rem] hover:bg-blue50 text-on-surface-elevation-1"
          >
            <s-icon size="3xl" :icon="'ic-v2-community-attach-emoji-line'" class="shrink-0" />
            <span class="flex-1 flex items-start gap-4 text-lg leading-sm font-medium">
              {{ $t('studio.lnb.common_2depth_studio_dev_guide') }}
            </span>
            <s-icon
              size="xl"
              icon="ic-v2-control-web-link-line"
              class="shrink-0 mt-2 text-on-surface-elevation-4"
            />
          </nuxt-link>
          <lnb-item
            icon="ic-v2-object-notice-line"
            hasNewNotify
            :to="`/${locale}${NOTICE_PAGE_URL}`"
          >
            {{ $t('studio.lnb.common_2depth_studio_noti') }}
          </lnb-item>
          <lnb-item :iconSvg="IconPlay" :to="`/${locale}${TUTORIAL_PAGE_URL}`">
            {{ $t('studio.lnb.common_2depth_tutorial_video') }}
          </lnb-item>
        </div>
      </div>
    </div>

    <!-- LNB 배너 -->
    <a class="cursor-pointer mt-20 block aspect-[4/3]" @click.prevent="onClickLnbBrandImg">
      <img
        :src="lnbBannerSrc"
        alt="쉽고, 새로워진 스토브 스튜디오!-가이드 보기"
        class="w-full h-full object-cover"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, nextTick, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import * as LnbBanner from '@/assets/images/common/lnb-banner';
import CreatorNews from '@/components/app/lnb/creator-news.vue';
import LnbItem from '@/components/app/lnb/lnb-item.vue';
import TeamSelector from '@/components/app/lnb/team-selector.vue';
import { MAX_PRODUCTS_ON_LNB } from '@/constants/common.const';
import {
  BRAND_PAGE_URL,
  DEVELOPERS_STORE_INTRO_PATH,
  NOTICE_PAGE_URL,
  TUTORIAL_PAGE_URL
} from '@/constants/url.const';
import { MenuAuthorityIds } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import { useUserStore } from '@/stores/user.store';
import type { LNBItem } from '@/types/lnb.type';
import type { SelectedProductHistory } from '@/types/selected-products-history/selected-products-history-model.type';
import { getBaseUrlDevCenterBeta, redirectTo } from '@/utils/common.util';

import IconPlay from './icon-play.vue';

const { locale } = useI18n();

// Get locale code and convert it
const randomImageIndex = Math.floor(Math.random() * 3) + 1;
const lnbBanner = ref<Record<string, string>>(LnbBanner);
const lnbBannerSrc = computed(
  () => lnbBanner.value[`LNB${randomImageIndex}_${locale.value.replace('-', '_')}`]
);
const userStore = useUserStore();
const appStore = useAppStore();

const { selectedGroupId, userInfo } = storeToRefs(userStore);
const { lnbSelectedProductListHistory } = storeToRefs(appStore);

getLnbProductListOfUser();

const onClickLnbBrandImg = async () => {
  await redirectTo(`/${locale.value}${BRAND_PAGE_URL}`, {
    external: true,
    open: {
      target: '_blank'
    }
  });
};

const lnbItems = computed<LNBItem[]>(() => [
  {
    menuAuthId: MenuAuthorityIds.GROUP_HOME,
    label: 'studio.lnb.common_2depth_studio_home',
    icon: 'ic-v2-navigation-home-line',
    to: `/${locale.value}/${selectedGroupId.value}/home`
  },
  {
    menuAuthId: MenuAuthorityIds.PROJECT_PRODUCT,
    label: 'studio.lnb.common_2depth_product_management',
    icon: 'ic-v2-object-game-line',
    to: `/${locale.value}/${selectedGroupId.value}/projects`,
    maxChildrenNumber: MAX_PRODUCTS_ON_LNB,
    children: lnbSelectedProductListHistory.value.map((product: SelectedProductHistory) => ({
      to: `/${locale.value}/${product.groupId}/projects/${product.projectId}/products/${product.productNo}`,
      tag: product.productDetailLabel,
      name: product.productName,
      productNo: product.productNo,
      external: product.groupId !== selectedGroupId.value
    }))
  },
  {
    menuAuthId: MenuAuthorityIds.COLLECTION,
    label: 'studio.lnb.common_2depth_collection',
    icon: 'ic-v2-object-widget-line',
    to: `/${locale.value}/${selectedGroupId.value}/collections`
  },
  {
    menuAuthId: MenuAuthorityIds.STORE_COMMUNITY,
    label: 'studio.lnb.common_2depth_store_community',
    icon: 'ic-v2-navigation-community-line',
    to: 'operation/community',
    external: true
  },
  {
    menuAuthId: MenuAuthorityIds.STATISTICS,
    label: 'studio.lnb.common_2depth_studio_metrics',
    icon: 'ic-v2-object-graph-line',
    to: 'operation/report',
    external: true
  },
  {
    menuAuthId: MenuAuthorityIds.BILL_SETTLEMENT,
    label: 'studio.lnb.common_2depth_studio_settlement',
    icon: 'ic-v2-object-coin-line',
    to: 'operation/calculate/payment',
    external: true
  },
  {
    menuAuthId: MenuAuthorityIds.MEMBER_MANAGEMENT,
    label: 'studio.lnb.common_2depth_member_management',
    icon: 'ic-v2-navigation-profile-line',
    to: `/${locale.value}/${selectedGroupId.value}/members`
  },
  {
    menuAuthId: MenuAuthorityIds.BIMS_BANK_SETTING,
    label: 'studio.lnb.common_2depth_business_info',
    icon: 'ic-v2-navigation-bank-line',
    to: `/${locale.value}/${selectedGroupId.value}/business-bank`
  }
]);

const groupLnb = ref<HTMLElement | null>(null);
let beginLeft = 0;

onMounted(() => {
  nextTick(() => {
    if (groupLnb.value) {
      beginLeft = groupLnb.value.getBoundingClientRect().left;
    }

    scrollHandler();
    resizeAdjust();
  });
});

const scrollHandler = () => {
  window.addEventListener('scroll', () => {
    if (!groupLnb.value) {
      return;
    }
    updateHorizontalPosition();
  });
};

const updateHorizontalPosition = () => {
  if (!groupLnb.value) {
    return;
  }
  if (window.scrollX > 0) {
    groupLnb.value.style.left = `${beginLeft - window.scrollX}px`;
  } else {
    groupLnb.value.style.left = '';
    beginLeft = groupLnb.value.getBoundingClientRect().left;
  }
};

const resizeAdjust = () => {
  window.addEventListener('resize', () => {
    if (!groupLnb.value) {
      return;
    }
    updateHorizontalPosition();
  });
};
function getLnbProductListOfUser() {
  if (
    userInfo.value &&
    userInfo.value.memberNo &&
    appStore.isAlreadyFetchSelectedProductListHistory === false
  ) {
    appStore.fetchSelectedProductListHistory(selectedGroupId.value);
  }
}

const getDeveloperGuideUrl = (): string => {
  return `${getBaseUrlDevCenterBeta()}${DEVELOPERS_STORE_INTRO_PATH}`;
};
</script>
